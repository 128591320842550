import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/globalStyles.css';
import { useOauthStore } from './context/oauthContext';
import { MANAGE_WEBAUTHN, FEDERATE_PARAMS as FEDERATE_QUERY_PARAMS, QUERY_PARAM_LABELS, EMOTION_CACHE } from './globals/constants';
import { LoginView, LogoutView } from './templates/TemplateSelector';
import { getCustomerConfig, getPreferredAuthMethod, getRegionFromUserpoolId } from './globals/utils';
import { useLoginStore } from './context/loginContext';
import { AxiosFlashBar } from './components/AxiosFlashBar';
import { StencilProvider } from '@amzn/stencil-react-components/esm/context';
import * as CupidAuthService from './services/cupidAuthService';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/esm/spinner';
import { useFailoverStore } from './context/failoverContext';

function App() {
  const setClientId = useOauthStore((state) => state.setClientId);
  const setRedirectUri = useOauthStore((state) => state.setRedirectUri);
  const setOauthState = useOauthStore((state) => state.setOauthState);
  const setLoginHint = useOauthStore((state) => state.setLoginHint);
  const setNonce = useOauthStore((state) => state.setNonce);
  const setResponseType = useOauthStore((state) => state.setResponseType);
  const setScope = useOauthStore((state) => state.setScope);
  const setRelyingParty = useOauthStore((state) => state.setRelyingParty);
  const setDisplay = useOauthStore((state) => state.setDisplay);
  const { isFailover } = useFailoverStore().failoverPoolConfig;

  const [customerConfig] = useState(() => getCustomerConfig());

  const { setFailoverPoolConfig } = useFailoverStore()

  const setWebauthnEnabled = useLoginStore((state) => state.setWebauthnEnabled);
  const setPromptRegisterWebauthn = useLoginStore((state) => state.setPromptRegisterWebauthn);

  const [loaded, setLoaded] = useState(false);
  const [amplifyLoading, setAmplifyLoading] = useState(true);

  // Retrieves query params which come from the window.location.search field
  // In the case of a redirect back from a federated IdP the original Amazon Federate params must be saved before the redirect and retrieved
  // from local storage
  const getQueryParams = () => {
    let queryParams = new URLSearchParams(window.location.search);

    // Checking for query params from Amazon Federate in local storage in the case where this is a redirect back from a federated idp sign-in
    const federateQueryParams = localStorage.getItem(FEDERATE_QUERY_PARAMS);
    if (federateQueryParams) {
      queryParams = new URLSearchParams(federateQueryParams);
      localStorage.removeItem(FEDERATE_QUERY_PARAMS);
    }

    return queryParams;
  }

  useEffect(() => {
    const queryParams = getQueryParams();

    const clientId = queryParams.get(QUERY_PARAM_LABELS.CLIENT_ID);
    const redirectUri = queryParams.get(QUERY_PARAM_LABELS.REDIRECT_URI);
    const state = queryParams.get(QUERY_PARAM_LABELS.STATE);
    const loginHint = queryParams.get(QUERY_PARAM_LABELS.LOGIN_HINT);
    const nonce = queryParams.get(QUERY_PARAM_LABELS.NONCE);
    const responseType = queryParams.get(QUERY_PARAM_LABELS.RESPONSE_TYPE);
    const scope = queryParams.get(QUERY_PARAM_LABELS.SCOPE);
    const relyingParty = queryParams.get(QUERY_PARAM_LABELS.RELYING_PARTY);
    const display = queryParams.get(QUERY_PARAM_LABELS.DISPLAY);

    setClientId(clientId ? clientId : '');
    setNonce(nonce ? nonce : '');
    setResponseType(responseType ? responseType : '');
    setScope(scope ? scope : '');
    setRedirectUri(redirectUri ? redirectUri : '');
    setOauthState(state ? state : '');
    setLoginHint(loginHint ? decodeURIComponent(loginHint) : '');
    setRelyingParty(relyingParty ? relyingParty : '');
    setDisplay(display ? display : '');
    setLoaded(true);
  }, []);

  useEffect(() => {

    const enabled = getPreferredAuthMethod(customerConfig) === 'WebAuthN';

    setWebauthnEnabled(enabled);
    setPromptRegisterWebauthn(enabled)
  }, [customerConfig]);

  // We do not support Webauthn in DR Regions so for now, always setting this to false
  // Once DR is supported we must remove this useEffect
  useEffect(() => {
    if (isFailover) {
      setWebauthnEnabled(false);
    }
  }, [isFailover])

  useEffect(() => {
    async function setupAmplify() {
      let {
        userpoolId: cognitoUserPoolId,
        clientId: cognitoClientId,
        region: cognitoRegion
      } = customerConfig;
      let isPrimaryPool = true;
      let isFailover = false;

      try {
        // Query the correct userpool for active-active
        const { appClientId, userPoolId, region, isPrimary, userPoolProperties } = await CupidAuthService.getUserPool({ primaryPoolId: customerConfig.userpoolId });
        if (userPoolProperties?.isActiveActiveEnabled) {
          cognitoUserPoolId = userPoolId;
          cognitoClientId = appClientId;
          cognitoRegion = region;
          isPrimaryPool = isPrimary;
       }
      } catch (e) {
        // There is no need to handle this error,
        // the error context will handle flashing the error message
        // We can fall back to the default customer config anyhow
      }

      try {
        // Check if we are in failover mode
        const { appClientId: failoverClientId, userPoolId: failoverUserPoolId } = await CupidAuthService.getStatus({
          primaryPoolId: customerConfig.userpoolId
        });
        if (failoverClientId && failoverUserPoolId) {
          cognitoUserPoolId = failoverUserPoolId;
          cognitoClientId = failoverClientId;
          cognitoRegion = getRegionFromUserpoolId(failoverUserPoolId);
          isPrimaryPool = false;
          isFailover = true;
        }
      } catch (e) {
        // There is no need to handle this error,
        // the error context will handle flashing the error message
        // We can fall back to the default customer config anyhow
      }

      let authenticationFlowType = undefined;

      //active active can be considered the same as failover if we're not in the primary region...
      if (!isPrimaryPool) {
        setFailoverPoolConfig({
          region: cognitoRegion,
          appClientId: cognitoClientId,
          userPoolId: cognitoUserPoolId,
          isFailover,
        });
        authenticationFlowType = 'CUSTOM_AUTH';
      }

      Auth.configure({
        region: cognitoRegion,
        userPoolId: cognitoUserPoolId,
        userPoolWebClientId: cognitoClientId,
        authenticationFlowType,
        oauth: customerConfig.oauth ? {
          domain: customerConfig.oauth.domain,
          redirectSignIn: window.location.origin,
          redirectSignOut: window.location.origin,
          scope: ['phone', 'email', 'profile', 'openid'],
          responseType: 'code'
        } : {},
      });
    }
    setAmplifyLoading(true);
    setupAmplify().finally(() => setAmplifyLoading(false));
  }, []);


  return (
    <StencilProvider emotionCache={EMOTION_CACHE}>
      <BrowserRouter>
        <AxiosFlashBar customerConfig={customerConfig} />

        {!amplifyLoading &&
          <Routes>
            <Route path='/' element={loaded && <LoginView />} />
            <Route path='/logout' element={<LogoutView />} />
            <Route path={MANAGE_WEBAUTHN} element={<LoginView />} />
            <Route path="*" element={<LoginView />} />
          </Routes>
          || <Spinner data-testid="loading-spinner" size={SpinnerSize.Medium} />
        }
      </BrowserRouter>
    </StencilProvider>
  );
}

export default App;
